import 'bootstrap'
import AOS from 'aos';
import 'owl.carousel'
// import Rellax from "rellax"

// JavaScript
$(document).ready( function () {

  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  // 語言轉換下拉按鈕
  $("#lang_switch").hover(function(){
      $(this).find(".header-other-link-list").addClass("active");
    },function(){
      if (!$(event.target).closest('.header-other-link-list, #lang_switch').length) {
        $('body').find('.header-other-link-list').removeClass('active');
      }
      $(".header-other-link-list").removeClass("active");
    });

  // 會員下拉按鈕
  $("#user_switch").hover(function(){
      $(this).find(".header-other-link-list").addClass("active");
    },function(){
      if (!$(event.target).closest('.header-other-link-list, #lang_switch').length) {
        $('body').find('.header-other-link-list').removeClass('active');
      }
      $(".header-other-link-list").removeClass("active");
    });

  // pagination
  $('ul.pagination .prev .page-link').empty();
  $('ul.pagination .next .page-link').empty();

  // products tabs
  $(function(){
    var $tab = $('.products-categories a');
    $($tab. eq(0) .addClass('active').attr('href')).siblings('.products-list').hide();

    $tab.click(function(){
        event.preventDefault();
        $($(this). attr ('href')).show().siblings ('.products-list').hide();
        $(this).addClass('active'). siblings ('.active').removeClass('active');
    });
  });

} );

document.addEventListener("DOMContentLoaded", function () {
  AOS.init({
    once: true,
  });
});
